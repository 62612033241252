import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/SEO';
import ProjectsArchive from '../components/ProjectsArchive';

function Projects({ path }) {
  return (
    <Fragment>
      <SEO
        title="Projects"
        pathname={path}
      />
      <ProjectsArchive />
    </Fragment>
  );
}

Projects.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Projects;
