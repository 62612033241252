import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';
import slugify from '../../utils/slugify';
import projects from './projectArchiveData.js';


import './index.scss';

function ProjectsArchive() {
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' ? window.innerWidth < 768 : false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    useAnimateOnScroll();
  
    return (
      <section id="projects-archive" className="projects-archive section-padding bg-lightgrey">
        <div className="grid">
          <h2 className="projects-archive__title">
             <span data-aos="slice-up" data-aos-duration="400" data-aos-delay="100">All Projects</span>
          </h2>
          <div className="projects-archive__container">
            <table className="projects-archive__table">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Project</th>
                  {!isMobile && <th>Made at</th>}
                  {!isMobile && <th>Built with</th>}
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project, index) => (
                  <tr key={index}>
                    <td>{project.year}</td>
                    <td>{project.project}</td>
                    {!isMobile && <td>{project.madeAt}</td>}
                    {!isMobile && (
                      <td className="projects-archive__tech-container">
                        <ul className="projects-archive__tech">
                          {project.builtWith.map((tech, i) => (
                            <li key={i} className="projects-archive__tech-items">
                              {tech}
                            </li>
                          ))}
                        </ul>
                      </td>
                    )}
                    <td>
                      <a className="projects-archive__link" href={project.link} target="_blank" rel="noopener noreferrer">
                        {project.link}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </section>
    );
  }
  
  ProjectsArchive.propTypes = {
    // projects: PropTypes.array.isRequired,
  };
  
  export default ProjectsArchive;
  