const projects = [
  {
    year: 2024,
    project: "Revionics",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "Python", "Algolia", "GSAP"],
    link: "https://revionics.com/",
  },
  {
    year: 2024,
    project: "Nebo Website",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "GSAP", "jQuery"],
    link: "https://www.neboagency.com/",
  },
  {
    year: 2024,
    project: "AMY Awards",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "GSAP", "jQuery"],
    link: "https://www.amyawardsatl.com/",
  },
  {
    year: 2024,
    project: "BeKind365",
    madeAt: "Nebo",
    builtWith: ["Wordpress", "Three.js", "Ms SQL"],
    link: "https://bekind365.world/",
  },
  {
    year: 2024,
    project: "Foundation Technologies",
    madeAt: "Nebo",
    builtWith: ["PHP", "jQuery", "Ms SQL"],
    link: "https://www.foundationtechnologies.com/",
  },
  {
    year: 2024,
    project: "MMM Law",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "jQuery", "GSAP"],
    link: "https://www.mmmlaw.com/",
  },
  {
    year: 2023,
    project: "Golf House Academy",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "GSAP", "jQuery"],
    link: "https://golfhouseacademy.com/",
  },
  {
    year: 2022,
    project: "Contec",
    madeAt: "Nebo",
    builtWith: ["Hubspot", "jQuery", "Preact", "GSAP"],
    link: "https://www.contecinc.com/",
  },
  {
    year: 2022,
    project: "Starlight",
    madeAt: "Nebo",
    builtWith: ["Ruby on Rails", "GSAP", "jQuery", "AWS"],
    link: "https://www.starlighthomes.com/",
  },
  {
    year: 2022,
    project: "TPL",
    madeAt: "Nebo",
    builtWith: ["Wordpress", "PHP", "jQuery", "GSAP", "Ms SQL"],
    link: "https://www.tpl.org/",
  },
  {
    year: 2021,
    project: "Eleanor Auto",
    madeAt: "",
    builtWith: ["React", "GSAP", "styled-components", "Figma"],
    link: "https://eleanor-reform-test-zaidkhan.vercel.app/",
  },
  {
    year: 2021,
    project: "Suspect Gifs",
    madeAt: "",
    builtWith: ["React", "giphy API", "CSS"],
    link: "https://zaidkhan144.github.io/memory-game/",
  },
  {
    year: 2021,
    project: "Utah Jazz",
    madeAt: "",
    builtWith: ["React", "SASS", "Adobe XD"],
    link: "https://zaidkhan144.github.io/utah-jazz-project/",
  },
  {
    year: 2021,
    project: "Portfolio Project V1",
    madeAt: "",
    builtWith: ["JavaScript ES6", "jQuery", "Webpack"],
    link: "https://personal-web-v1.netlify.app/",
  },
  {
    year: 2021,
    project: "Milna",
    madeAt: "",
    builtWith: ["React", "Redux", "styled-components", "Axios"],
    link: "https://github.com/ZaidKhan144/milna-frontend",
  },
  {
    year: 2021,
    project: "Uptown Skate Shop",
    madeAt: "",
    builtWith: ["React", "styled-components", "Framer Motion"],
    link: "https://zaidkhan144.github.io/shopping-cart/#/",
  },
];

export default projects;
